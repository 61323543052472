<script setup lang="ts">
const props = defineProps({
    name: String,
    code: String,
    hex: String,
    lightText: Boolean,
    isActive: Boolean,
});

const emit = defineEmits(['update:active']);

const handleClick = () => {
    emit('update:active');
};
</script>

<template>
    <div
        :class="[
            'card option co-flex co-flex-col co-justify-center co-text-center co-p-[2px] co-items-center co-border-white  co-border-4 co-overflow-hidden co-rounded-xl co-shadow-inner',
            {
                'card--active': isActive,
                'co-text-white': lightText,
                'co-text-navy-blue': !lightText,
            },
        ]"
        :style="'background-color:' + hex"
        @click="handleClick"
    >
        <div class="co-p-4 co-py-8">
            <h3 class="co-text-base co-font-semibold">{{ name }}</h3>
            <!-- <h3 class="co-text-base">{{ code }}</h3> -->
        </div>
    </div>
</template>

<style scoped></style>
