<script setup lang="ts">
import { ref, watch } from 'vue';
import Card from '@/components/Card.vue';

// Images
import topBoxBeneath from '@/assets/images/top_box_position_beneath_lintel.webp';
import topBoxBehind from '@/assets/images/top_box_position_behind_lintel.webp';

interface Option {
    imageSrc: string;
    title: string;
    description: string;
    descriptionSize: string;
    price: number;
}

const props = defineProps<{
    currentStep: number;
}>();

const emit = defineEmits(['updatedData', 'enabledNextButton', 'error']);
const selectionType = ref<'checkbox' | 'radio'>('radio');
const selectedIndexes = ref<number[]>([]);

const inputData = ref({
    question: 'Top Box Position',
    title: '',
    answer: '',
    cost: 0
});

watch(
    () => inputData.value,
    () => {
        if (inputData.value.title !== '') {
            inputData.value.answer = inputData.value.title
            emit('enabledNextButton', true);
            emit('updatedData', inputData.value);
        } else {
            emit('error', 'Please select a top box position');
        }
    },
    { deep: true }
);

watch(
    () => selectedIndexes.value,
    () => {
        inputData.value.title = options.value[selectedIndexes.value[0]].title;
        inputData.value.cost = Number(options.value[selectedIndexes.value[0]].price) * 100;
    },
    { deep: true }
);

// Card mockup
const options = ref<Option[]>([
    {
        imageSrc: topBoxBeneath,
        title: 'Beneath Lintel',
        description:
            'The Guide Runners on and in-between fit, sit between the pillars and the top box most commonly sits under your lintel.',
        descriptionSize: 'co-text-base lg:co-text-lg',
        price: 0
    },
    {
        imageSrc: topBoxBehind,
        title: 'Behind Lintel',
        description: 'Description for right side.',
        descriptionSize: 'co-text-base lg:co-text-lg',
        price: 0
    },
]);
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Top Box Position</h2>
        <!-- <p> <strong>TWF55:</strong> Top Box 205mm x 205mm <strong>TWF77:</strong> Top Box 300mm x 300mm </p> -->
    </div>

    <div class="co-grid xs:co-grid-cols-2 2xl:co-grid-cols-3 co-items-start co-gap-4 co-select-none">
        <Card
            v-for="(option, index) in options"
            :key="index"
            :imageSrc="option.imageSrc"
            :title="option.title"
            :description="option.description"
            :descriptionSize="option.descriptionSize"
            :toggleImage="false"
            :price="option.price"
            :type="selectionType"
            v-model:modelValue="selectedIndexes"
            :index="index"
        />
    </div>
</template>

<style scoped></style>
