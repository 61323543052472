<script setup lang="ts">
import Measurements from '@/assets/images/measurements.svg';

const emit = defineEmits(['updatedData', 'elementClicked', 'elementHovered', 'elementMouseOut']);

const props = defineProps<{
    topBrick: string;
    bottomBrick: string;
    floorLintel: string;
    topBox: string;
    leftPillar: string;
    rightPillar: string;
    highlightedId: number | null;
    activeId: number | null;
}>();

const getOpacity = (id: number) => {
    return props.highlightedId === null || props.highlightedId === id ? 1 : 0.45;
};
const isActive = (id: number) => {
    return props.activeId === id;
};
const handleClick = (id: number) => {
    emit('elementClicked', { id, highlightedId: id, activeId: id });
};

const handleMouseOver = (id: number) => {
    emit('elementHovered', id);
};

const handleMouseOut = (id: number) => {
    emit('elementMouseOut', id);
};
</script>

<template>
    <svg width="276" height="258" viewBox="0 0 276 258" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="measurements">
            <g id="Garage">
                <path
                    id="Garage_2"
                    d="M274.135 0H1.86539C0.833471 0 0 0.856773 0 1.89287V257.99H2.24243L3.73078 255.928L40.3797 220.442H65.0465L66.1379 218.141L81.5274 186.141C81.5571 186.071 81.5671 186.002 81.5671 185.932V86.3061H194.423V185.932C194.423 186.002 194.443 186.071 194.473 186.141L209.862 218.131L210.844 220.173L210.973 220.442H235.61L272.279 256.306L273.49 258H276V1.89287C276 0.856773 275.166 0 274.135 0ZM39.6851 219.804L3.73078 254.583V4.30379L39.6851 39.4238V219.804ZM80.6443 185.822L66.1379 215.979V69.2603L80.6443 86.0271V185.822ZM81.319 85.3696L66.1379 68.3437H209.416L194.681 85.3696H81.319ZM209.862 215.979L195.356 185.822V86.0072L209.862 69.2503V215.979ZM235.382 219.496H212.66V66.9191C212.66 66.1321 212.035 65.5044 211.261 65.5044H64.2924C63.5184 65.5044 63.3398 66.0686 63.3398 66.9191V219.486H40.6079V39.6829H235.382V219.486V219.496ZM40.3301 38.7364L4.56425 3.78574H271.436L235.66 38.7364H40.3301ZM272.269 254.583L236.315 219.804V39.4238L272.269 4.30379V254.593V254.583Z"
                    fill="#231F20"
                />
            </g>
            <g
                :id="'1'"
                :style="{ opacity: getOpacity(1) }"
                :class="{ active: isActive(1) }"
                @click="handleClick(1)"
                @mouseover="handleMouseOver(1)"
                @mouseout="handleMouseOut(1)"
            >
                <path
                    id="Bar"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M68.5 101H69.5V104H206.496V106H69.5V109H68.5V101ZM207.5 101H206.5V109H207.5V101Z"
                    fill="#231F20"
                />
                <path
                    id="Bar_Blue"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M69.5 104V101H68.5V109H69.5V106H206.496V104H69.5ZM206.5 109H207.5V101H206.5V109Z"
                    fill="#0094FF"
                    class="bar_blue"
                />
                <g id="Measurement">
                    <path
                        id="Value_BG"
                        d="M113 92H161.325C162.248 92 163 92.9169 163 94.0433V115.957C163 117.083 162.248 118 161.325 118H113V92Z"
                        fill="#709AAB"
                    />
                    <text
                        id="Value"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="11"
                        font-weight="300"
                        letter-spacing="0em"
                    >
                        <tspan x="115.5" y="109.16">{{ topBrick }}</tspan>
                    </text>
                    <path
                        id="Button"
                        d="M94.4307 92.0131H113V118H94.4307C93.6448 118 93 117.162 93 116.14V93.86C93 92.8383 93.6448 92 94.4307 92V92.0131Z"
                        fill="#115673"
                    />
                    <text
                        id="Item_Number"
                        fill="#EFE05F"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="19"
                        font-weight="800"
                        letter-spacing="0em"
                    >
                        <tspan x="96.8037" y="111.64">1</tspan>
                    </text>
                    <path
                        id="Stroke_White"
                        d="M113.212 91V91.0131H95.5174V91H94.5174C92.951 91 92 92.4859 92 93.86V116.14C92 117.514 92.951 119 94.5174 119H161.365C163.015 119 164 117.416 164 115.957V94.0433C164 92.5844 163.015 91 161.365 91H114.212H113.212Z"
                        stroke="white"
                        stroke-width="2"
                    />
                    <path
                        id="Stroke_Blue"
                        d="M113.318 90.2V90.2144H94.0608V90.2H93.5608C92.9415 90.2 92.4157 90.5338 92.0614 90.9983C91.7068 91.463 91.5 92.0831 91.5 92.746V117.254C91.5 117.917 91.7068 118.537 92.0614 119.002C92.4157 119.466 92.9415 119.8 93.5608 119.8H162.319C162.976 119.8 163.532 119.441 163.907 118.94C164.282 118.439 164.5 117.77 164.5 117.052V92.9477C164.5 92.2303 164.282 91.5609 163.907 91.0597C163.532 90.5585 162.976 90.2 162.319 90.2H113.818H113.318Z"
                        stroke="#0094FF"
                        class="stroke_blue"
                    />
                </g>
            </g>
            <g
                :id="'2'"
                :style="{ opacity: getOpacity(2) }"
                :class="{ active: isActive(2) }"
                @click="handleClick(2)"
                @mouseover="handleMouseOver(2)"
                @mouseout="handleMouseOut(2)"
            >
                <path
                    id="Bar_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M68.5 196H69.5V199H206.496V201H69.5V204H68.5V196ZM207.5 196H206.5V204H207.5V196Z"
                    fill="#231F20"
                />
                <path
                    id="Bar_Blue_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M69.5 199V196H68.5V204H69.5V201H206.496V199H69.5ZM206.5 204H207.5V196H206.5V204Z"
                    fill="#0094FF"
                    class="bar_blue"
                />
                <g id="Measurement_2">
                    <path
                        id="Value_BG_2"
                        d="M113 187H161.325C162.248 187 163 187.917 163 189.043V210.957C163 212.083 162.248 213 161.325 213H113V187Z"
                        fill="#709AAB"
                    />
                    <text
                        id="Value_2"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="11"
                        font-weight="300"
                        letter-spacing="0em"
                    >
                        <tspan x="115.5" y="204.16">{{ bottomBrick }}</tspan>
                    </text>
                    <path
                        id="Button_2"
                        d="M94.4307 187.013H113V213H94.4307C93.6448 213 93 212.162 93 211.14V188.86C93 187.838 93.6448 187 94.4307 187V187.013Z"
                        fill="#115673"
                    />
                    <text
                        id="Item_Number_2"
                        fill="#EFE05F"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="19"
                        font-weight="800"
                        letter-spacing="0em"
                    >
                        <tspan x="96.8037" y="206.64">2</tspan>
                    </text>
                    <path
                        id="Stroke_White_2"
                        d="M113.212 186V186.013H95.5174V186H94.5174C92.951 186 92 187.486 92 188.86V211.14C92 212.514 92.951 214 94.5174 214H161.365C163.015 214 164 212.416 164 210.957V189.043C164 187.584 163.015 186 161.365 186H114.212H113.212Z"
                        stroke="white"
                        stroke-width="2"
                    />
                    <path
                        id="Stroke_Blue_2"
                        d="M113.318 185.2V185.214H94.0608V185.2H93.5608C92.9415 185.2 92.4157 185.534 92.0614 185.998C91.7068 186.463 91.5 187.083 91.5 187.746V212.254C91.5 212.917 91.7068 213.537 92.0614 214.002C92.4157 214.466 92.9415 214.8 93.5608 214.8H162.319C162.976 214.8 163.532 214.441 163.907 213.94C164.282 213.439 164.5 212.77 164.5 212.052V187.948C164.5 187.23 164.282 186.561 163.907 186.06C163.532 185.559 162.976 185.2 162.319 185.2H113.818H113.318Z"
                        stroke="#0094FF"
                        class="stroke_blue"
                    />
                </g>
            </g>
            <g
                :id="'3'"
                :style="{ opacity: getOpacity(3) }"
                :class="{ active: isActive(3) }"
                @click="handleClick(3)"
                @mouseover="handleMouseOver(3)"
                @mouseout="handleMouseOut(3)"
            >
                <path
                    id="Bar_3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M176 70H184V71H181V220H184V221H176V220H179V71H176V70Z"
                    fill="#231F20"
                />
                <path
                    id="Bar_Blue_3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M176 70H184V71H181V220H184V221H176V220H179V71H176V70Z"
                    fill="#0094FF"
                    class="bar_blue"
                />
                <g id="Measurement_3">
                    <path
                        id="Value_BG_3"
                        d="M180 128H228.325C229.248 128 230 128.917 230 130.043V151.957C230 153.083 229.248 154 228.325 154H180V128Z"
                        fill="#709AAB"
                    />
                    <text
                        id="Value_3"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="11"
                        font-weight="300"
                        letter-spacing="0em"
                    >
                        <tspan x="182.5" y="145.16">{{ floorLintel }}</tspan>
                    </text>
                    <path
                        id="Button_3"
                        d="M161.431 128.013H180V154H161.431C160.645 154 160 153.162 160 152.14V129.86C160 128.838 160.645 128 161.431 128V128.013Z"
                        fill="#115673"
                    />
                    <text
                        id="Item_Number_3"
                        fill="#EFE05F"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="19"
                        font-weight="800"
                        letter-spacing="0em"
                    >
                        <tspan x="163.804" y="147.64">3</tspan>
                    </text>
                    <path
                        id="Stroke_White_3"
                        d="M180.212 127V127.013H162.517V127H161.517C159.951 127 159 128.486 159 129.86V152.14C159 153.514 159.951 155 161.517 155H228.365C230.015 155 231 153.416 231 151.957V130.043C231 128.584 230.015 127 228.365 127H181.212H180.212Z"
                        stroke="white"
                        stroke-width="2"
                    />
                    <path
                        id="Stroke_Blue_3"
                        d="M180.318 126.2V126.214H161.061V126.2H160.561C159.941 126.2 159.416 126.534 159.061 126.998C158.707 127.463 158.5 128.083 158.5 128.746V153.254C158.5 153.917 158.707 154.537 159.061 155.002C159.416 155.466 159.941 155.8 160.561 155.8H229.319C229.976 155.8 230.532 155.441 230.907 154.94C231.282 154.439 231.5 153.77 231.5 153.052V128.948C231.5 128.23 231.282 127.561 230.907 127.06C230.532 126.559 229.976 126.2 229.319 126.2H180.818H180.318Z"
                        stroke="#0094FF"
                        class="stroke_blue"
                    />
                </g>
            </g>
            <g
                :id="'4'"
                :style="{ opacity: getOpacity(4) }"
                :class="{ active: isActive(4) }"
                @click="handleClick(4)"
                @mouseover="handleMouseOver(4)"
                @mouseout="handleMouseOut(4)"
            >
                <path
                    id="Bar_4"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M136 41.5H144V42.5H136V41.5ZM144 62.5008H141.003L141.003 42.5013H139.003L139.003 62.5008H136V63.5008H144V62.5008Z"
                    fill="#231F20"
                />
                <path
                    id="Bar_Blue_4"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M136 41.5H144V42.5H136V41.5ZM144 62.5008H141.003L141.003 42.5013H139.003L139.003 62.5008H136V63.5008H144V62.5008Z"
                    fill="#0094FF"
                    class="bar_blue"
                />
                <g id="Measurement_4">
                    <path
                        id="Value_BG_4"
                        d="M127 8H175.325C176.248 8 177 8.91687 177 10.0433V31.9567C177 33.0831 176.248 34 175.325 34H127V8Z"
                        fill="#709AAB"
                    />
                    <text
                        id="Value_4"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="11"
                        font-weight="300"
                        letter-spacing="0em"
                    >
                        <tspan x="129.5" y="25.16">{{ topBox }}</tspan>
                    </text>
                    <path
                        id="Button_4"
                        d="M108.431 8.0131H127V34H108.431C107.645 34 107 33.1617 107 32.1401V9.85996C107 8.83829 107.645 8 108.431 8V8.0131Z"
                        fill="#115673"
                    />
                    <text
                        id="Item_Number_4"
                        fill="#EFE05F"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="19"
                        font-weight="800"
                        letter-spacing="0em"
                    >
                        <tspan x="110.785" y="27.64">4</tspan>
                    </text>
                    <path
                        id="Stroke_White_4"
                        d="M127.212 7V7.0131H109.517V7H108.517C106.951 7 106 8.48589 106 9.85996V32.1401C106 33.5141 106.951 35 108.517 35H175.365C177.015 35 178 33.4156 178 31.9567V10.0433C178 8.58436 177.015 7 175.365 7H128.212H127.212Z"
                        stroke="white"
                        stroke-width="2"
                    />
                    <path
                        id="Stroke_Blue_4"
                        d="M127.318 6.2V6.21441H108.061V6.2H107.561C106.941 6.2 106.416 6.53378 106.061 6.99828C105.707 7.46303 105.5 8.08307 105.5 8.74595V33.2541C105.5 33.9169 105.707 34.537 106.061 35.0017C106.416 35.4662 106.941 35.8 107.561 35.8H176.319C176.976 35.8 177.532 35.4415 177.907 34.9403C178.282 34.4391 178.5 33.7697 178.5 33.0523V8.94765C178.5 8.23028 178.282 7.56094 177.907 7.05967C177.532 6.55854 176.976 6.2 176.319 6.2H127.818H127.318Z"
                        stroke="#0094FF"
                        class="stroke_blue"
                    />
                </g>
            </g>
            <g
                :id="'5'"
                :style="{ opacity: getOpacity(5) }"
                :class="{ active: isActive(5) }"
                @click="handleClick(5)"
                @mouseover="handleMouseOver(5)"
                @mouseout="handleMouseOut(5)"
            >
                <path
                    id="Bar_5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M42.5 196H43.5V199H60.5V196H61.5V204H60.5V201H43.5V204H42.5V196Z"
                    fill="#231F20"
                />
                <path
                    id="Bar_Blue_5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M42.5 196H43.5V199H60.5V196H61.5V204H60.5V201H43.5V204H42.5V196Z"
                    fill="#0094FF"
                    class="bar_blue"
                />
                <g id="Measurement_5">
                    <path
                        id="Value_BG_5"
                        d="M33 164.3H81.3247C82.2483 164.3 83 165.217 83 166.343V188.257C83 189.383 82.2483 190.3 81.3247 190.3H33V164.3Z"
                        fill="#709AAB"
                    />
                    <text
                        id="Value_5"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="11"
                        font-weight="300"
                        letter-spacing="0em"
                    >
                        <tspan x="35.5" y="181.46">{{ leftPillar }}</tspan>
                    </text>
                    <path
                        id="Button_5"
                        d="M14.4307 164.313H33V190.3H14.4307C13.6448 190.3 13 189.462 13 188.44V166.16C13 165.138 13.6448 164.3 14.4307 164.3V164.313Z"
                        fill="#115673"
                    />
                    <text
                        id="Item_Number_5"
                        fill="#EFE05F"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="19"
                        font-weight="800"
                        letter-spacing="0em"
                    >
                        <tspan x="16.8037" y="183.94">5</tspan>
                    </text>
                    <path
                        id="Stroke_White_5"
                        d="M33.2121 163.3V163.313H15.5174V163.3H14.5174C12.951 163.3 12 164.786 12 166.16V188.44C12 189.814 12.951 191.3 14.5174 191.3H81.3653C83.0155 191.3 84 189.716 84 188.257V166.343C84 164.884 83.0155 163.3 81.3653 163.3H34.2121H33.2121Z"
                        stroke="white"
                        stroke-width="2"
                    />
                    <path
                        id="Stroke_Blue_5"
                        d="M33.3182 162.5V162.514H14.0608V162.5H13.5608C12.9415 162.5 12.4157 162.834 12.0614 163.298C11.7068 163.763 11.5 164.383 11.5 165.046V189.554C11.5 190.217 11.7068 190.837 12.0614 191.302C12.4157 191.766 12.9415 192.1 13.5608 192.1H82.3186C82.9759 192.1 83.5324 191.741 83.9073 191.24C84.2823 190.739 84.5 190.07 84.5 189.352V165.248C84.5 164.53 84.2823 163.861 83.9073 163.36C83.5324 162.859 82.9759 162.5 82.3186 162.5H33.8182H33.3182Z"
                        stroke="#0094FF"
                        class="stroke_blue"
                    />
                </g>
            </g>
            <g
                :id="'6'"
                :style="{ opacity: getOpacity(6) }"
                :class="{ active: isActive(6) }"
                @click="handleClick(6)"
                @mouseover="handleMouseOver(6)"
                @mouseout="handleMouseOut(6)"
            >
                <path
                    id="Bar_6"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M214.5 196H215.5V199H232.5V196H233.5V204H232.5V201H215.5V204H214.5V196Z"
                    fill="#231F20"
                />
                <path
                    id="Bar_Blue_6"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M214.5 196H215.5V199H232.5V196H233.5V204H232.5V201H215.5V204H214.5V196Z"
                    fill="#0094FF"
                    class="bar_blue"
                />
                <g id="Measurement_6">
                    <path
                        id="Value_BG_6"
                        d="M213.5 164H261.825C262.748 164 263.5 164.917 263.5 166.043V187.957C263.5 189.083 262.748 190 261.825 190H213.5V164Z"
                        fill="#709AAB"
                    />
                    <text
                        id="Value_6"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="11"
                        font-weight="300"
                        letter-spacing="0em"
                    >
                        <tspan x="216" y="181.16">{{ rightPillar }}</tspan>
                    </text>
                    <path
                        id="Button_6"
                        d="M194.931 164.013H213.5V190H194.931C194.145 190 193.5 189.162 193.5 188.14V165.86C193.5 164.838 194.145 164 194.931 164V164.013Z"
                        fill="#115673"
                    />
                    <text
                        id="Item_Number_6"
                        fill="#EFE05F"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-family="houschka-pro, sans-serif"
                        font-size="19"
                        font-weight="800"
                        letter-spacing="0em"
                    >
                        <tspan x="197.304" y="183.64">6</tspan>
                    </text>
                    <path
                        id="Stroke_White_6"
                        d="M213.712 163V163.013H196.017V163H195.017C193.451 163 192.5 164.486 192.5 165.86V188.14C192.5 189.514 193.451 191 195.017 191H261.865C263.515 191 264.5 189.416 264.5 187.957V166.043C264.5 164.584 263.515 163 261.865 163H214.712H213.712Z"
                        stroke="white"
                        stroke-width="2"
                    />
                    <path
                        id="Stroke_Blue_6"
                        d="M213.818 162.2V162.214H194.561V162.2H194.061C193.441 162.2 192.916 162.534 192.561 162.998C192.207 163.463 192 164.083 192 164.746V189.254C192 189.917 192.207 190.537 192.561 191.002C192.916 191.466 193.441 191.8 194.061 191.8H262.819C263.476 191.8 264.032 191.441 264.407 190.94C264.782 190.439 265 189.77 265 189.052V164.948C265 164.23 264.782 163.561 264.407 163.06C264.032 162.559 263.476 162.2 262.819 162.2H214.318H213.818Z"
                        stroke="#0094FF"
                        class="stroke_blue"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<style scoped>
svg {
    width: 100%;
    height: 100%;
}
g {
    transition: opacity 0.5s ease;
    cursor: pointer;
}
.bar_blue,
.stroke_blue {
    display: none;
}
.active .bar_blue,
.active .stroke_blue {
    display: block;
}
</style>
