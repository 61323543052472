<script setup lang="ts">
import { ref, watch } from 'vue';
import InfoIconAlt from '@/components/InfoIconAlt.vue';
const props = defineProps<{
    currentStep: number;
}>();

const customerData = ref({
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    townCity: '',
    postcode: '',
    email: '',
    phone: '',
    deliveryMethod: 'Delivery',
    deliveryCost: 6500
})

const emit = defineEmits(['updateCustomer', 'enabledNextButton', 'error']);

watch(
    () => customerData.value,
    () => {
        // Validate phone number
        const phoneRegex = /^0[0-9]{10}$/;
        if (!phoneRegex.test(customerData.value.phone)) {
            emit('error', 'Please enter a valid phone number');
            return;
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(customerData.value.email)) {
            emit('error', 'Please enter a valid email address');
            return;
        }

        if (
            customerData.value.firstName !== '' &&
            customerData.value.lastName !== '' &&
            customerData.value.address !== '' &&
            customerData.value.townCity !== '' &&
            customerData.value.postcode !== '' &&
            customerData.value.email !== '' &&
            customerData.value.phone !== ''
        ) {
            switch (customerData.value.deliveryMethod.toLowerCase()) {
                case 'delivery':
                    customerData.value.deliveryCost = 6500
                    break;
                default:
                    customerData.value.deliveryCost = 0
                    break;
            }
            //customerData.value = {...customerData.value};
            emit('error', '');
            emit('enabledNextButton', true);
            //emit('updatedData', inputData.value);
            emit('updateCustomer', customerData.value)
        } else {
            emit('error', 'Please fill in all fields');
        }
    },
    { deep: true }
);
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Nearly Done</h2>
    </div>

    <div class="co-flex co-flex-col lg:co-grid lg:co-grid-cols-2 co-gap-4">
        <div class="co-flex-1 co-flex co-flex-col">
            <h3 class="co-mb-4 co-text-lg lg:co-text-xl co-text-twf-blue co-font-bold">Your Details</h3>
            <div class="co-flex co-flex-col co-gap-4 co-p-4 co-bg-light-grey co-rounded-lg">
                <div class="field-group">
                    <label class="form-label" for="firstname">Name</label>

                    <div class="co-flex co-gap-2">
                        <input
                            v-model="customerData.firstName"
                            class="text-field co-w-full"
                            type="text"
                            id="firstname"
                            firstname="name"
                            placeholder="First Name"
                        />
                        <input
                            v-model="customerData.lastName"
                            class="text-field co-w-full"
                            type="text"
                            id="lastname"
                            name="lastname"
                            placeholder="Last Name"
                        />
                    </div>
                </div>
                <div class="field-group">
                    <label class="form-label" for="company">Company Name</label>
                    <input
                        v-model="customerData.companyName"
                        class="text-field"
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Company Name"
                    />
                </div>

                <div class="co-flex co-flex-col co-gap-2">
                    <div class="co-flex co-flex-col co-space-y-1 co-mt-2">
                        <label class="form-label">Address</label>
                        <input
                            v-model="customerData.address"
                            class="text-field"
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Address"
                        />
                    </div>
                    <div class="field-group">
                        <input
                            v-model="customerData.townCity"
                            class="text-field"
                            type="text"
                            id="towncity"
                            name="towncity"
                            placeholder="Town/City"
                        />
                    </div>
                    <div class="field-group">
                        <input
                            v-model="customerData.postcode"
                            class="text-field"
                            type="text"
                            id="postcode"
                            name="postcode"
                            placeholder="Postcode"
                        />
                    </div>
                </div>

                <div class="co-flex co-flex-col co-space-y-1 co-mt-2">
                    <input v-model="customerData.email" class="text-field" type="email" id="email" name="email" placeholder="Email Address" />
                </div>
                <div class="field-group">
                    <input v-model="customerData.phone" class="text-field" type="tel" id="phone" name="phone" placeholder="Phone Number" />
                </div>
            </div>
        </div>
        <div class="co-flex-1 co-flex co-flex-col co-justify-start">
            <h3 class="co-mb-4 co-text-lg lg:co-text-xl co-text-twf-blue co-font-bold">Delivery Options</h3>
            <div class="co-flex co-flex-col co-gap-4 co-p-4 co-bg-light-grey co-rounded-lg">
                <label
                    class="co-flex co-gap-4 co-space-y-1 co-p-4 co-bg-white co-border-2 co-rounded-lg co-cursor-pointer"
                    :class="customerData.deliveryMethod === 'delivery' ? 'co-bg-[#eff6ff] co-border-light-blue' : 'co-border-input-grey'"
                    for="delivery"
                >
                    <input
                        class="co-w-8 co-h-8 co-rounded co-border co-border-grey"
                        type="radio"
                        id="delivery"
                        name="delivery_method"
                        v-model="customerData.deliveryMethod"
                        value="Delivery"
                        checked
                    />
                    <div class="co-w-full co-flex co-justify-between">
                        <span> Delivered to mainland England & Wales* </span>
                        <span class="co-font-semibold">&pound;65.00</span>
                    </div>
                </label>
                <label
                    class="co-flex co-gap-4 co-space-y-1 co-p-4 co-bg-white co-border-2 co-rounded-lg co-cursor-pointer"
                    :class="customerData.deliveryMethod === 'collection' ? 'co-bg-[#eff6ff] co-border-light-blue' : 'co-border-input-grey'"
                    for="collection"
                >
                    <input
                        v-model="customerData.deliveryMethod"
                        class="co-w-8 co-h-8 co-rounded co-border co-border-grey"
                        type="radio"
                        id="collection"
                        value="Collection"
                        name="delivery_method"
                    />
                    <div class="co-w-full co-flex co-justify-between">
                        <span>Collect in person</span>
                        <span class="co-font-semibold">Free</span>
                    </div>
                </label>
                <div class="co-p-4 co-bg-[#ddd] co-rounded-lg co-text-sm">
                    <div class="co-flex co-gap-4 co-items-start">
                        <InfoIconAlt />
                        <span class="co-flex-1">We do not deliver to any offshore island, including the Isle of Wight and Channel Islands. For addresses in Scotland please contact us for a delivery price before placing your order.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.field-group {
    @apply co-flex co-flex-col co-space-y-1;
}
.form-label {
    @apply co-text-base co-text-dark-grey co-font-semibold co-select-none;
}
.text-field {
    @apply co-p-2 co-rounded-lg co-border-2 co-border-input-grey;
}
.text-field:focus {
    @apply co-outline-light-blue;
}
</style>
