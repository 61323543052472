// useIsMobile.ts
import { ref, onMounted, onUnmounted } from 'vue';

export function useIsTablet(breakpoint = 980) {
    const isTablet = ref(window.innerWidth < breakpoint);

    const updateIsTablet = () => {
        isTablet.value = window.innerWidth < breakpoint;
    };

    onMounted(() => {
        window.addEventListener('resize', updateIsTablet);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateIsTablet);
    });

    return { isTablet };
}
