<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import type { ComponentPublicInstance } from 'vue';
import { useIsMobile } from '@/composables/useIsMobile';
import { useIsTablet } from '@/composables/useIsTablet';
import Measurements from '@/components/Measurements.vue';

const { isMobile } = useIsMobile();
const { isTablet } = useIsTablet();

const props = defineProps<{
    currentStep: number;
}>();

const highlightedId = ref<number | null>(null);
const activeId = ref<number | null>(null);
const visibleDiv = ref<number>(1);

const emit = defineEmits(['updatedData', 'measurementsComplete', 'error', 'enabledNextButton', 'goBack']);

const goBack = () => {
    emit('goBack');
};

const inputData = ref({
    question: 'Measurements',
    topBrick: '',
    bottomBrick: '',
    floorLintel: '',
    topBox: '',
    leftPillar: '',
    rightPillar: '',
    answer: ''
});

// References to the input fields
const inputRefs = ref<(HTMLInputElement | null)[]>([]);

const setInputRef = (el: Element | ComponentPublicInstance | null, index: number) => {
    inputRefs.value[index] = el as HTMLInputElement | null;
};

// Watch for changes in inputData, enforce max length, and emit updated data
watch(
    inputData,
    (newValue) => {
        let allFieldsFilled = true;

        Object.keys(newValue).forEach((key) => {
            // Skip the question key as it is not a field
            if (key === 'question' || key === 'answer') return;

            const fieldValue = newValue[key as keyof typeof inputData.value];

            if (fieldValue.length > 4) {
                newValue[key as keyof typeof inputData.value] = fieldValue.slice(0, 4);
            }

            // Check if the value is empty
            if (fieldValue === '') {
                emit('error', 'Please complete all fields');
                allFieldsFilled = false;
            }

            // Check if the value is a number
            if (fieldValue === '0') {
                emit('error', 'Please enter a number');
                allFieldsFilled = false;
            }

            // Check if the value is a number
            if (isNaN(Number(fieldValue))) {
                emit('error', 'Please enter a number');
                allFieldsFilled = false;
            }

            // Check if the value is a negative number
            if (Number(fieldValue) < 0) {
                emit('error', 'Please enter a positive number');
                allFieldsFilled = false;
            }
        });

        inputData.value.answer = `Top brick: ${inputData.value.topBrick}mm
        Bottom brick: ${inputData.value.bottomBrick}mm
        Floor lintel: ${inputData.value.floorLintel}mm
        Top box: ${inputData.value.topBox}mm
        Left pillar: ${inputData.value.leftPillar}mm
        Right pillar: ${inputData.value.rightPillar}mm
        `

        emit('updatedData', inputData.value);

        if (allFieldsFilled) {
            emit('error', '');
            emit('enabledNextButton', true);
            emit('measurementsComplete');
        }
    },
    { deep: true }
);

const formattedData = computed(() => {
    return {
        topBrick: inputData.value.topBrick ? `${inputData.value.topBrick}mm` : '0mm',
        bottomBrick: inputData.value.bottomBrick ? `${inputData.value.bottomBrick}mm` : '0mm',
        floorLintel: inputData.value.floorLintel ? `${inputData.value.floorLintel}mm` : '0mm',
        topBox: inputData.value.topBox ? `${inputData.value.topBox}mm` : '0mm',
        leftPillar: inputData.value.leftPillar ? `${inputData.value.leftPillar}mm` : '0mm',
        rightPillar: inputData.value.rightPillar ? `${inputData.value.rightPillar}mm` : '0mm',
    };
});

const handleMouseEnter = (id: number) => {
    highlightedId.value = id;
};

const handleMouseLeave = () => {
    highlightedId.value = null;
};

const handleFocus = (id: number) => {
    activeId.value = id;
};

const handleBlur = () => {
    activeId.value = null;
};
const handleFocusAndMouseEnter = (id: number) => {
    handleFocus(id);
    handleMouseEnter(id);
};
const blurAll = () => {
    handleBlur();
    handleMouseLeave();
};
const enforceMaxLength = (event: Event, maxLength: number) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length > maxLength) {
        target.value = target.value.slice(0, maxLength);
    }
    inputData.value[target.name as keyof typeof inputData.value] = target.value; // Update inputData in real-time
};

const handleElementClicked = ({ id, highlightedId: hId, activeId: aId }: { id: number; highlightedId: number; activeId: number }) => {
    visibleDiv.value = id;
    highlightedId.value = hId;
    activeId.value = aId;

    // Ensure the corresponding input field is focused
    setTimeout(() => {
        inputRefs.value[id - 1]?.focus();
    }, 0);
};

const handleElementHovered = (id: number) => {
    highlightedId.value = id;
};

const handleElementMouseOut = (id: number) => {
    highlightedId.value = activeId.value; // Reset to activeId
};
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Measurements</h2>
        <p>
            <span class="co-inline md:co-hidden">Click each numbered box on the diagram and enter the measurement in the textfield. </span> 
            <span class="co-inline-block">All measurements in mm please.</span>
            <span class="co-block">
                <strong>Tip:</strong>
                Need more help? Take a look at our <button @click="goBack" class="co-text-twf-blue co-font-semibold co-underline">Measuring Guide Video</button>.
            </span>
        </p>
    </div>

    <div class="co-flex co-flex-col sm:co-grid sm:co-grid-cols-12 co-gap-4">
        <div v-if="!isTablet" class="co-flex co-flex-col co-gap-4 co-select-none co-order-1 sm:co-order-[0] co-col-span-4">
            <div
                class="input-1 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                @mouseenter="handleMouseEnter(1)"
                @mouseleave="handleMouseLeave"
            >
                <div class="co-flex co-p-2 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">1</div>
                <div class="co-flex co-flex-col co-w-full co-space-y-1 co-pt-2 co-pr-3 co-pb-3 co-pl-3">
                    <label class="co-text-sm" for="top-brick">Measure opening at top brick to brick</label>
                    <div class="input-wrapper co-relative co-flex co-w-full">
                        <input
                            class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                            type="text"
                            id="top-brick"
                            name="top-brick"
                            maxlength="4"
                            v-model="inputData.topBrick"
                            @focusin="handleFocusAndMouseEnter(1)"
                            @focusout="handleBlur"
                            @input="enforceMaxLength($event, 4)"
                            :ref="(el) => setInputRef(el, 0)"
                        />
                        <div
                            class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                            >mm</div
                        >
                    </div>
                </div>
            </div>
            <div
                class="input-1 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                @mouseenter="handleMouseEnter(2)"
                @mouseleave="handleMouseLeave"
            >
                <div class="co-flex co-p-2 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">2</div>
                <div class="co-flex co-flex-col co-w-full co-space-y-1 co-pt-2 co-pr-3 co-pb-3 co-pl-3">
                    <label class="co-text-sm" for="bottom-brick">Measure opening at bottom brick to brick</label>
                    <div class="input-wrapper co-relative co-flex co-w-full">
                        <input
                            class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                            type="text"
                            id="bottom-brick"
                            name="bottom-brick"
                            maxlength="4"
                            v-model="inputData.bottomBrick"
                            @focusin="handleFocusAndMouseEnter(2)"
                            @focusout="handleBlur"
                            @input="enforceMaxLength($event, 4)"
                            :ref="(el) => setInputRef(el, 1)"
                        />
                        <div
                            class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                            >mm</div
                        >
                    </div>
                </div>
            </div>
            <div
                class="input-3 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                @mouseenter="handleMouseEnter(3)"
                @mouseleave="handleMouseLeave"
            >
                <div class="co-flex co-p-2 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">3</div>
                <div class="co-flex co-flex-col co-w-full co-space-y-1 co-pt-2 co-pr-3 co-pb-3 co-pl-3">
                    <label class="co-text-sm" for="lintel">Measure floor to bottom of lintel</label>
                    <div class="input-wrapper co-relative co-flex co-w-full">
                        <input
                            class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                            type="text"
                            id="lintel"
                            name="lintel"
                            maxlength="4"
                            v-model="inputData.floorLintel"
                            @focusin="handleFocusAndMouseEnter(3)"
                            @focusout="handleBlur"
                            @input="enforceMaxLength($event, 4)"
                            :ref="(el) => setInputRef(el, 2)"
                        />
                        <div
                            class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                            >mm</div
                        >
                    </div>
                </div>
            </div>
            <div
                class="input-4 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                @mouseenter="handleMouseEnter(4)"
                @mouseleave="handleMouseLeave"
            >
                <div class="co-flex co-p-2 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">4</div>
                <div class="co-flex co-flex-col co-w-full co-space-y-1 co-pt-2 co-pr-3 co-pb-3 co-pl-3">
                    <label class="co-text-sm" for="top-box">Measure top box clearance</label>
                    <div class="input-wrapper co-relative co-flex co-w-full">
                        <input
                            class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                            type="text"
                            id="top-box"
                            name="top-box"
                            maxlength="4"
                            v-model="inputData.topBox"
                            @focusin="handleFocusAndMouseEnter(4)"
                            @focusout="handleBlur"
                            @input="enforceMaxLength($event, 4)"
                            :ref="(el) => setInputRef(el, 3)"
                        />
                        <div
                            class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                            >mm</div
                        >
                    </div>
                </div>
            </div>
            <div
                class="input-5 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                @mouseenter="handleMouseEnter(5)"
                @mouseleave="handleMouseLeave"
            >
                <div class="co-flex co-p-2 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">5</div>
                <div class="co-flex co-flex-col co-w-full co-space-y-1 co-pt-2 co-pr-3 co-pb-3 co-pl-3">
                    <label class="co-text-sm" for="left-pillar">Left pillar width</label>
                    <div class="input-wrapper co-relative co-flex co-w-full">
                        <input
                            class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                            type="text"
                            id="left-pillar"
                            name="left-pillar"
                            maxlength="4"
                            v-model="inputData.leftPillar"
                            @focusin="handleFocusAndMouseEnter(5)"
                            @focusout="handleBlur"
                            @input="enforceMaxLength($event, 4)"
                            :ref="(el) => setInputRef(el, 4)"
                        />
                        <div
                            class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                            >mm</div
                        >
                    </div>
                </div>
            </div>
            <div
                class="input-6 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                @mouseenter="handleMouseEnter(6)"
                @mouseleave="handleMouseLeave"
            >
                <div class="co-flex co-p-2 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">6</div>
                <div class="co-flex co-flex-col co-w-full co-space-y-1 co-pt-2 co-pr-3 co-pb-3 co-pl-3">
                    <label class="co-text-sm" for="right-pillar">Right pillar width</label>
                    <div class="input-wrapper co-relative co-flex co-w-full">
                        <input
                            class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                            type="text"
                            id="right-pillar"
                            name="right-pillar"
                            maxlength="4"
                            v-model="inputData.rightPillar"
                            @focusin="handleFocusAndMouseEnter(6)"
                            @focusout="handleBlur"
                            @input="enforceMaxLength($event, 4)"
                            :ref="(el) => setInputRef(el, 5)"
                        />
                        <div
                            class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                            >mm</div
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="co-flex co-flex-col co-gap-4 co-col-span-12 tb:co-col-span-8">
            <div v-if="isTablet">
                <div
                    v-if="visibleDiv === 1"
                    class="input-1 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                    @mouseenter="handleMouseEnter(1)"
                    @mouseleave="handleMouseLeave"
                >
                    <div class="co-flex co-p-4 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">1</div>
                    <div class="co-flex co-flex-col co-w-full co-space-y-1 co-p-4">
                        <label class="co-text-sm" for="top-brick">Measure opening at top brick to brick</label>
                        <div class="input-wrapper co-relative co-flex co-w-full">
                            <input
                                class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                                type="text"
                                id="top-brick"
                                name="top-brick"
                                maxlength="4"
                                v-model="inputData.topBrick"
                                @focusin="handleFocusAndMouseEnter(1)"
                                @focusout="handleBlur"
                                @input="enforceMaxLength($event, 4)"
                                :ref="(el) => setInputRef(el, 0)"
                            />
                            <div
                                class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                                >mm</div
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="visibleDiv === 2"
                    class="input-2 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                    @mouseenter="handleMouseEnter(2)"
                    @mouseleave="handleMouseLeave"
                >
                    <div class="co-flex co-p-4 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">2</div>
                    <div class="co-flex co-flex-col co-w-full co-space-y-1 co-p-4">
                        <label class="co-text-sm" for="top-brick">Measure opening at bottom brick to brick</label>
                        <div class="input-wrapper co-relative co-flex co-w-full">
                            <input
                                class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                                type="text"
                                id="top-brick"
                                name="top-brick"
                                maxlength="4"
                                v-model="inputData.bottomBrick"
                                @focusin="handleFocusAndMouseEnter(2)"
                                @focusout="handleBlur"
                                @input="enforceMaxLength($event, 4)"
                                :ref="(el) => setInputRef(el, 1)"
                            />
                            <div
                                class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                                >mm</div
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="visibleDiv === 3"
                    class="input-3 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                    @mouseenter="handleMouseEnter(3)"
                    @mouseleave="handleMouseLeave"
                >
                    <div class="co-flex co-p-4 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">3</div>
                    <div class="co-flex co-flex-col co-w-full co-space-y-1 co-p-4">
                        <label class="co-text-sm" for="lintel">Measure floor to bottom of lintel</label>
                        <div class="input-wrapper co-relative co-flex co-w-full">
                            <input
                                class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                                type="text"
                                id="lintel"
                                name="lintel"
                                maxlength="4"
                                v-model="inputData.floorLintel"
                                @focusin="handleFocusAndMouseEnter(3)"
                                @focusout="handleBlur"
                                @input="enforceMaxLength($event, 4)"
                                :ref="(el) => setInputRef(el, 2)"
                            />
                            <div
                                class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                                >mm</div
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="visibleDiv === 4"
                    class="input-4 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                    @mouseenter="handleMouseEnter(4)"
                    @mouseleave="handleMouseLeave"
                >
                    <div class="co-flex co-p-4 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">4</div>
                    <div class="co-flex co-flex-col co-w-full co-space-y-1 co-p-4">
                        <label class="co-text-sm" for="top-box">Measure top box clearance</label>
                        <div class="input-wrapper co-relative co-flex co-w-full">
                            <input
                                class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                                type="text"
                                id="top-box"
                                name="top-box"
                                maxlength="4"
                                v-model="inputData.topBox"
                                @focusin="handleFocusAndMouseEnter(4)"
                                @focusout="handleBlur"
                                @input="enforceMaxLength($event, 4)"
                                :ref="(el) => setInputRef(el, 3)"
                            />
                            <div
                                class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                                >mm</div
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="visibleDiv === 5"
                    class="input-5 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                    @mouseenter="handleMouseEnter(5)"
                    @mouseleave="handleMouseLeave"
                >
                    <div class="co-flex co-p-4 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">5</div>
                    <div class="co-flex co-flex-col co-w-full co-space-y-1 co-p-4">
                        <label class="co-text-sm" for="left-pillar">Left pillar width</label>
                        <div class="input-wrapper co-relative co-flex co-w-full">
                            <input
                                class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                                type="text"
                                id="left-pillar"
                                name="left-pillar"
                                maxlength="4"
                                v-model="inputData.leftPillar"
                                @focusin="handleFocusAndMouseEnter(5)"
                                @focusout="handleBlur"
                                @input="enforceMaxLength($event, 4)"
                                :ref="(el) => setInputRef(el, 4)"
                            />
                            <div
                                class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                                >mm</div
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="visibleDiv === 6"
                    class="input-6 co-flex co-flex-row co-bg-light-grey co-rounded-lg"
                    @mouseenter="handleMouseEnter(6)"
                    @mouseleave="handleMouseLeave"
                >
                    <div class="co-flex co-p-4 co-bg-twf-blue co-text-yellow co-text-xl co-font-bold co-rounded-l-lg">6</div>
                    <div class="co-flex co-flex-col co-w-full co-space-y-1 co-p-4">
                        <label class="co-text-sm" for="right-pillar">Right pillar width</label>
                        <div class="input-wrapper co-relative co-flex co-w-full">
                            <input
                                class="co-w-full co-p-2 co-border-2 co-border-grey co-rounded co-outline-light-blue co-outline-offset-4"
                                type="text"
                                id="right-pillar"
                                name="right-pillar"
                                maxlength="4"
                                v-model="inputData.rightPillar"
                                @focusin="handleFocusAndMouseEnter(6)"
                                @focusout="handleBlur"
                                @input="enforceMaxLength($event, 4)"
                                :ref="(el) => setInputRef(el, 5)"
                            />
                            <div
                                class="co-absolute co-right-0 co-top-0 co-pointer-events-none co-bottom-0 co-w-14 co-flex co-justify-center co-items-center co-bg-grey co-text-white co-font-semibold co-rounded-r"
                                >mm</div
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="co-flex co-flex-col co-justify-start co-items-center co-w-full">
                <Measurements
                    :topBrick="formattedData.topBrick"
                    :bottomBrick="formattedData.bottomBrick"
                    :floorLintel="formattedData.floorLintel"
                    :topBox="formattedData.topBox"
                    :leftPillar="formattedData.leftPillar"
                    :rightPillar="formattedData.rightPillar"
                    :highlightedId="highlightedId"
                    :activeId="activeId"
                    @elementClicked="handleElementClicked"
                    @elementHovered="handleElementHovered"
                    @elementMouseOut="handleElementMouseOut"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>
