<script setup lang="ts">
const emit = defineEmits(['next']);

const props = defineProps<{
    isEnabled: Boolean;
}>();
const next = () => {
    if (props.isEnabled) {
        emit('next');
    }
};
</script>

<template>
    <button
        :class="[
            'co-flex-1 co-flex co-justify-end co-items-center co-space-x-2 co-px-4 co-py-2 co-font-bold co-text-lg co-bg-twf-blue hover:co-bg-navy-blue co-text-yellow co-rounded co-select-none',
            { 'co-opacity-50 co-cursor-not-allowed co-pointer-events-none': !isEnabled },
        ]"
        :disabled="!isEnabled"
        @click="next"
    >
        <span> Next </span>
        <svg class="co-w-4 co-h-4" width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.70117 17.098L9.29921 9.49998L1.70117 1.90194"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
        </svg>
    </button>
</template>
