<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

const wrapper = ref<HTMLElement | null>(null);
const content = ref<HTMLElement | null>(null);
const shadowTop = ref<HTMLElement | null>(null);
const shadowBottom = ref<HTMLElement | null>(null);

const handleScroll = () => {
    if (content.value && wrapper.value && shadowTop.value && shadowBottom.value) {
        const contentScrollHeight = content.value.scrollHeight - wrapper.value.offsetHeight;
        const currentScroll = content.value.scrollTop / contentScrollHeight;
        shadowTop.value.style.opacity = currentScroll.toString();
        shadowBottom.value.style.opacity = (1 - currentScroll).toString();
    }
};

onMounted(() => {
    if (content.value) {
        content.value.addEventListener('scroll', handleScroll);
    }
});

onUnmounted(() => {
    if (content.value) {
        content.value.removeEventListener('scroll', handleScroll);
    }
});
</script>

<template>
    <div class="wrapper co-flex-1" ref="wrapper">
        <div class="content co-block scroll-shadows co-scroll-container co-mb-3 co-pb-3 -co-mr-[1rem] co-pr-[1rem] co-border-input-grey" ref="content">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped></style>
