<script setup lang="ts">
const emit = defineEmits(['goBack']);

const goBack = () => {
    emit('goBack');
};
</script>

<template>

  <button
    class="co-flex-2 co-flex co-items-center co-space-x-2 co-px-4 co-py-2 co-font-bold co-text-lg co-bg-mid-grey hover:co-bg-navy-blue co-text-white co-rounded md:co-order-3 xl:co-order-[0] co-select-none"
    @click="goBack">
    <svg class="co-w-4 co-h-4" width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.29883 1.90196L1.70079 9.5L9.29883 17.098" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
    <span>
      Back
    </span>
  </button>
</template>
