import './assets/main.css';

import { createApp } from 'vue';
import { createWebHashHistory, createRouter } from 'vue-router';

import App from './App.vue';

// Views
import IntroVideo from './views/IntroVideo.vue';
import ChooseSize from './views/ChooseSize.vue';
import Measurements from './views/Measurements.vue';
import DoorColour from './views/DoorColour.vue';
import GarageDoorPosition from './views/GarageDoorPosition.vue';
import TopBoxPosition from './views/TopBoxPosition.vue';
import MotorPosition from './views/MotorPosition.vue';
import GuideRunnersTopBoxColour from './views/GuideRunnersTopBoxColour.vue';
import Extras from './views/Extras.vue';
import CustomerDetails from './views/CustomerDetails.vue';
import Complete from './views/Complete.vue';

// Routes
const routes = [
    { path: '/', component: IntroVideo },
    { path: '/step/1', component: Measurements },
    { path: '/step/2', component: ChooseSize },
    { path: '/step/3', component: DoorColour },
    { path: '/step/4', component: GarageDoorPosition },
    { path: '/step/5', component: TopBoxPosition },
    { path: '/step/6', component: MotorPosition },
    { path: '/step/7', component: GuideRunnersTopBoxColour },
    { path: '/step/8', component: Extras },
    { path: '/step/9', component: CustomerDetails },
    { path: '/step/10', component: Complete },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

createApp(App).use(router).mount('#calculator');
