<script setup lang="ts">
import { ref, watch } from 'vue';
import ColourSwatch from '../components/ColourSwatch.vue';

const props = defineProps<{
    currentStep: number;
}>();

const emit = defineEmits(['updatedData', 'enabledNextButton', 'error']);

const activeIndex = ref<number>(-1);
const inputData = ref({
    question: 'Guide Runners and Top Box Colour',
    answer: '',
    cost: 0,
    color: {
        name: '',
        code: '',
        hex: '',
        lightText: false,
    },
    name: ''
});

const setActive = (index: number) => {
    activeIndex.value = index;
};

// Set default value
emit('updatedData', inputData.value);

watch(
    () => inputData.value,
    () => {
        if (inputData.value.color.name !== '') {
            inputData.value.answer = inputData.value.color.name;
            emit('enabledNextButton', true);
            emit('updatedData', inputData.value);
        } else {
            emit('error', 'Please select a colour');
        }
    },
    { deep: true }
);

watch(
    () => activeIndex.value,
    () => {
        inputData.value.color = colours[activeIndex.value];
        inputData.value.cost = colours[activeIndex.value].price;
        inputData.value.name = colours[activeIndex.value].name;
    },
    { deep: true }
);

// Colours
const colours = [
    {
        name: 'White',
        code: 'RAL 9016',
        hex: '#F1F0EA',
        lightText: false,
        price: 0
    },
    {
        name: 'Dark Brown',
        code: 'RAL 8019',
        hex: '#3D3635',
        lightText: true,
        price: 0
    },
    {
        name: 'Black',
        code: 'RAL 9011',
        hex: '#27292B',
        lightText: true,
        price: 4000
    },
    {
        name: 'Anthracite Grey',
        code: 'RAL 7016',
        hex: '#383E42',
        lightText: true,
        price: 4000
    },
];
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Guide Runners & Top Box Colour</h2>
    </div>

    <div
        class="co-grid co-grid-cols-3 sm:co-grid-cols-4 md:co-grid-cols-3 lg:co-grid-cols-4 2xl:co-grid-cols-5 co-gap-1 md:co-gap-4 co-select-none"
    >
        <ColourSwatch
            v-for="(colour, index) in colours"
            :key="index"
            :name="colour.name"
            :code="colour.code"
            :hex="colour.hex"
            :price="colour.price"
            :lightText="colour.lightText"
            :isActive="activeIndex === index"
            @update:active="() => setActive(index)"
        />
    </div>
</template>

<style scoped></style>
