<script setup lang="ts">
import { ref, watch } from 'vue';
import ColourSwatch from '../components/ColourSwatch.vue';

const props = defineProps<{
    currentStep: number;
}>();

const emit = defineEmits(['updatedData', 'enabledNextButton', 'error']);

const inputData = ref({
    question: 'Door Colour',
    answer: '',
    cost: 0,
    color: {
        name: '',
        code: '',
        hex: '',
        lightText: false,
    },
    name: ''
});

const activeIndex = ref<number>(-1);

const setActive = (index: number) => {
    activeIndex.value = index;
};

// Set default value
emit('updatedData', inputData.value);

watch(
    () => inputData.value,
    () => {
        if (inputData.value.color.name !== '') {
            inputData.value.answer = inputData.value.color.name;
            emit('enabledNextButton', true);
            emit('updatedData', inputData.value);
        } else {
            emit('error', 'Please select a colour');
        }
    },
    { deep: true }
);

watch(
    () => activeIndex.value,
    () => {
        inputData.value.color = colours[activeIndex.value];
        inputData.value.cost = colours[activeIndex.value].price
        inputData.value.answer = inputData.value.color.name
        inputData.value.name = inputData.value.color.name
    },
    { deep: true }
);

// Colours
const colours = [
    {
        name: 'White',
        code: 'RAL 9016',
        hex: '#F1F0EA',
        lightText: false,
        price: 0
    },
    {
        name: 'Black',
        code: 'RAL 9011',
        hex: '#27292B',
        lightText: true,
        price: 2000
    },
    {
        name: 'Anthracite Grey',
        code: 'RAL 7016',
        hex: '#383E42',
        lightText: true,
        price: 1000
    },
    {
        name: 'Slate Grey',
        code: 'RAL 7015',
        hex: '#4F5358',
        lightText: true,
        price: 1000
    },
    {
        name: 'Ivory',
        code: 'RAL 1015',
        hex: '#E6D2B5',
        lightText: false,
        price: 1000
    },
    {
        name: 'Bordeaux',
        code: 'RAL 3004',
        hex: '#6B1C23',
        lightText: true,
        price: 1000
    },
    {
        name: 'Dark Blue',
        code: 'RAL 5011',
        hex: '#1A2B3C',
        lightText: true,
        price: 1000
    },
    {
        name: 'Moss Green',
        code: 'RAL 6005',
        hex: '#114232',
        lightText: true,
        price: 1000
    },
    {
        name: 'Fir Green',
        code: 'RAL 6009',
        hex: '#27352A',
        lightText: true,
        price: 1000
    },
    {
        name: 'Agate Grey',
        code: 'RAL 7038',
        hex: '#D9D9D9',
        lightText: false,
        price: 1000
    },
    {
        name: 'Light Brown',
        code: 'RAL 8014',
        hex: '#4A3526',
        lightText: true,
        price: 1000
    },
    {
        name: 'Dark Brown',
        code: 'RAL 8019',
        hex: '#3D3635',
        lightText: true,
        price: 0
    },
    {
        name: 'Cream',
        code: 'RAL 9001',
        hex: '#E9E0D2',
        lightText: false,
        price: 1000
    },
    {
        name: 'Silver',
        code: 'RAL 9006',
        hex: '#A1A1A0',
        lightText: true,
        price: 1000
    },
    {
        name: 'Golden Oak',
        code: '',
        hex: '#7E4B26',
        lightText: true,
        price: 3000
    },
    {
        name: 'Chartwell Green',
        code: '',
        hex: '#8A9977',
        lightText: true,
        price: 3500
    },
];
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Door Colour</h2>
    </div>

    <div
        class="co-grid co-grid-cols-3 sm:co-grid-cols-4 md:co-grid-cols-3 lg:co-grid-cols-4 2xl:co-grid-cols-5 co-gap-1 md:co-gap-4 co-select-none"
    >
        <ColourSwatch
            v-for="(colour, index) in colours"
            :class="'co-basis-1/2'"
            :key="index"
            :name="colour.name"
            :code="colour.code"
            :hex="colour.hex"
            :price="colour.price"
            :lightText="colour.lightText"
            :isActive="activeIndex === index"
            @update:active="() => setActive(index)"
        />
    </div>
</template>

<style scoped></style>
