<script setup lang="ts">
import { ref, watch } from 'vue';
import Card from '../components/Card.vue';

// Images
import extraItem01 from '@/assets/images/extras_hard_wired_safety_edge.webp';
import extraItem02 from '@/assets/images/extras_wireless_kit.webp';
import extraItem03 from '@/assets/images/extras_wireless_teleco_safety_edge.webp';
import extraItem04 from '@/assets/images/extras_remote_fobs.webp';
import extraItem05 from '@/assets/images/extras_external_manual_override.webp';

interface Option {
    imageSrc: string;
    title: string;
    smallTitle: boolean;
    width: string;
    description: string;
    descriptionSize: string;
    price: number;
}

const props = defineProps<{
    currentStep: number;
}>();

const emit = defineEmits(['updatedData', 'enabledNextButton', 'error']);

const options = ref<Option[]>([
    {
        imageSrc: extraItem01,
        title: 'Hard Wired Safety Edge Kit with 2 Remote Controls',
        smallTitle: true,
        width: 'yes',
        description:
            'This system is a standard hard wired safety edge kit. This allows your door to be fully automatic with remote controls. The safety edge kit detects any obstructions in the way while closing.',
        descriptionSize: 'co-text-sm lg:co-text-base',
        price: 13500,
    },
    {
        imageSrc: extraItem02,
        title: 'Wireless Safety Edge Kit with 2 Remote Controls',
        smallTitle: true,
        width: 'yes',
        description:
            'This system is a wireless safety edge kit which connects to the receiver box via battery power. This system allows your door to operate fully automatic with remote controls. The safety edge kit detects any obstruction in the way while closing.',
        descriptionSize: 'co-text-sm lg:co-text-base',
        price: 15000,
    },
    {
        imageSrc: extraItem03,
        title: 'Teleco Receiver Box Wireless Safety Edge Kit with 2 Remote Controls',
        smallTitle: true,
        width: 'yes',
        description:
            'Teleco Receiver Box wireless safety edge kit with 2x remote controls. If you have any other questions please do not hesitate to get in touch.',
        descriptionSize: 'co-text-sm lg:co-text-base',
        price: 18000,
    },
    {
        imageSrc: extraItem04,
        title: 'Extra Remote Control (for both Hard Wired & Wireless Safety Edge Kits)',
        smallTitle: true,
        width: 'yes',
        description: 'This is an integrated alarm system for the wireless teleco safety edge system.',
        descriptionSize: 'co-text-sm lg:co-text-base',
        price: 2000,
    },
    {
        imageSrc: extraItem05,
        title: 'External Manual Override Kit (For when no other access to your garage)',
        smallTitle: true,
        width: 'yes',
        description:
            'We recommend you purchase an external manual override kit if you have no other access to your garage other than your garage door. This override kit allows you to open the door from the outside in the case of a power cut.',
        descriptionSize: 'co-text-sm lg:co-text-base',
        price: 11500,
    },
]);

const selectionType = ref<'checkbox' | 'radio'>('checkbox');
const selectedIndexes = ref<number[]>([]);

interface InputData {
    question: string,
    extras: ExtraData[],
    answer: string,
    cost: number
}
interface ExtraData {
    title: string,
    price: number
}
const inputData = ref<InputData>({
    question: 'Extras',
    extras: [],
    answer: 'NA',
    cost: 0
});

watch(
    () => selectedIndexes,
    () => {
        inputData.value.extras = selectedIndexes.value.map((index) => ({
            'title': options.value[index].title,
            'price': options.value[index].price
        }));
        inputData.value.answer = selectedIndexes.value.map((index) => options.value[index].title).join(', ');
        inputData.value.cost = inputData.value.extras.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.price)
        }, 0);

        if (selectedIndexes.value.length === 0) {
            inputData.value = {
                question: 'Extras',
                extras: [],
                answer: 'NA',
                cost: 0
            }
        }

        emit('updatedData', inputData.value);
    },
    { deep: true }
);

emit('enabledNextButton', true);
emit('updatedData', inputData.value);
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Extras</h2>
    </div>

    <div class="co-grid xs:co-grid-cols-2 sm:co-grid-cols-3 md:co-grid-cols-2 lg:co-grid-cols-3 co-gap-2 co-select-none">
        <Card
            v-for="(option, index) in options"
            :key="index"
            :imageSrc="option.imageSrc"
            :title="option.title"
            :description="option.description"
            :descriptionSize="option.descriptionSize"
            :toggleImage="true"
            :width="option.width"
            :price="option.price"
            :type="selectionType"
            :shortImage="true"
            v-model:modelValue="selectedIndexes"
            :index="index"
        />
    </div>
</template>

<style scoped></style>
../components/Card.vue
