<script setup lang="ts">
import { ref, watch, defineAsyncComponent } from 'vue';
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';

//const VimeoPlayer = defineAsyncComponent(() => import('@/components/VimeoPlayer.vue'));
import VimeoPlayer from '@/components/VimeoPlayer.vue';
const modalOpen = ref<boolean>(false);
const playerRef = ref<HTMLElement | null>(null);

interface Props {
    thumb: string;
    thumbWidth: number;
    thumbHeight: number;
    thumbAlt: string;
    videoId: string;
    videoWidth: number;
    videoHeight: number;
}

const props = defineProps<Props>();
</script>

<template>
    <div class="co-flex co-justify-center">
        <!-- Video thumbnail -->
        <button
            class="co-relative co-flex co-justify-center co-items-center focus:co-outline-none co-focus-visible:ring co-focus-visible:ring-indigo-300 co-rounded-xl co-group"
            @click="modalOpen = true"
            aria-label="Watch the video"
        >
            <img
                class="co-rounded-2xl co-shadow-2xl co-transition-shadow co-duration-300 co-ease-in-out"
                :src="props.thumb"
                :width="props.thumbWidth"
                :height="props.thumbHeight"
                alt="Modal video thumbnail"
            />
            <!-- Play icon -->
            <svg
                class="co-absolute co-pointer-events-none group-hover:co-scale-110 co-transition-transform co-duration-300 co-ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
            >
                <circle class="co-fill-white" cx="36" cy="36" r="36" fill-opacity=".8" />
                <path
                    class="co-fill-light-blue drop-shadow-2xl"
                    d="M44 36a.999.999 0 0 0-.427-.82l-10-7A1 1 0 0 0 32 29V43a.999.999 0 0 0 1.573.82l10-7A.995.995 0 0 0 44 36V36c0 .001 0 .001 0 0Z"
                />
            </svg>
        </button>
        <!-- End: Video thumbnail -->

        <TransitionRoot :show="modalOpen" as="template">
            <Dialog :initialFocus="playerRef" @close="modalOpen = false">
                <!-- Modal backdrop -->
                <TransitionChild
                    className="co-fixed co-inset-0 co-z-[99999] co-bg-black co-bg-opacity-50 co-transition-opacity"
                    enter="co-transition co-ease-out co-duration-200"
                    enterFrom="co-opacity-0"
                    enterTo="co-opacity-100"
                    leave="co-transition co-ease-out co-duration-100"
                    leaveFrom="co-opacity-100"
                    leaveTo="co-opacity-0"
                    aria-hidden="true"
                />
                <!-- End: Modal backdrop -->

                <!-- Modal dialog -->
                <TransitionChild
                    className="co-fixed co-inset-0 co-z-[99999] co-flex co-px-4 md:co-px-6 co-py-6"
                    enter="co-transition co-ease-out co-duration-300"
                    enterFrom="co-opacity-0 co-scale-75"
                    enterTo="co-opacity-100 co-scale-100"
                    leave="co-transition co-ease-out co-duration-200"
                    leaveFrom="co-opacity-100 co-scale-100"
                    leaveTo="co-opacity-0 co-scale-75"
                >
                    <div class="co-w-full co-h-full md:co-max-w-5xl co-mx-auto co-flex co-items-center">
                        <DialogPanel
                            class="co-flex co-items-center co-w-full co-max-h-full co-rounded-2xl co-shadow-2xl co-aspect-video co-bg-black co-overflow-hidden"
                        >
                            <VimeoPlayer ref="playerRef" :videoId="props.videoId" :playerWidth="720" />
                        </DialogPanel>
                    </div>
                </TransitionChild>
                <!-- End: Modal dialog -->
            </Dialog>
        </TransitionRoot>
    </div>
</template>
<style scoped></style>
