<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { PropType } from 'vue';
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default defineComponent({
    name: 'VimeoPlayerComponent',
    components: { vueVimeoPlayer },
    props: {
        videoId: {
            type: String as PropType<string>,
            required: true,
        },
    },
    setup() {
        const height = ref(500);
        const options = ref({
            muted: true,
            autoplay: true,
        });
        const playerReady = ref(false);
        const player = ref<InstanceType<typeof vueVimeoPlayer> | null>(null);

        const onReady = () => {
            playerReady.value = true;
        };

        const play = () => {
            player.value?.play();
        };

        const pause = () => {
            player.value?.pause();
        };

        return {
            height,
            options,
            playerReady,
            player,
            onReady,
            play,
            pause,
        };
    },
});
</script>

<template>
    <vue-vimeo-player
        class="vimeo-player"
        autoplay
        ref="player"
        tabindex="0"
        :video-id="videoId"
        @ready="onReady"
        :player-height="height"
        :options="{ responsive: true }"
    ></vue-vimeo-player>
</template>

<style scoped>
.vimeo-player {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
}
</style>
