<script setup lang="ts">
import { ref, computed } from 'vue';
import ScrollShadow from './ScrollShadow.vue';
import BackButton from '@/components/BackButton.vue';
import NextButton from '@/components/NextButton.vue';
import InfoIconAlt from '@/components/InfoIconAlt.vue';

const emit = defineEmits(['goBack', 'goNext']);

const goBack = () => {
    emit('goBack');
};

const goNext = () => {
    emit('goNext');
};

const summaryVisible = ref(false);
const toggleSummary = () => {
    summaryVisible.value = !summaryVisible.value;
};

const props = defineProps<{
    totalQuote: String;
    currentStep: number;
    inputData: any;
    customerData: any;
    formattedTotalQuote: String;
    enabledNextButton: Boolean;
    error: String;
}>();

interface ExtraData {
    title: string,
    price: number
}
const totalExtras = computed(() => {
    if (!props.inputData[8].extras) {
        return 0;
    }

    return props.inputData[8].extras.reduce((accumulator:number, currentValue:ExtraData) => {
        return accumulator + Number(currentValue.price)
    }, 0);
});
</script>

<template>
    <div class="co-flex co-flex-col co-justify-between">
        <div v-if="currentStep == 1" class="co-flex-1"></div>
        <div
            :class="
                currentStep >= 1
                    ? 'md:co-h-header-offset co-flex co-flex-col co-justify-between co-items-stretch'
                    : 'co-flex co-flex-col co-justify-between'
            "
        >
            <div
                class="summary co-h-full co-flex co-flex-col md:co-bg-light-grey co-select-none"
                v-if="currentStep >= 1"
                :class="currentStep != 4 ? 'co-flex-' : 'co-flex-'"
            >
                <div
                    v-if="currentStep >= 2"
                    :class="
                        summaryVisible
                            ? 'co-relative co-z-20 co-translate-y-0 co-transition co-duration-300 co-p-6 co-pb-0 md:co-p-4 md:co-pb-0 xl:co-p-6 xl:co-pb-0 co-bg-light-grey'
                            : 'co-relative co-z-20 co-translate-y-full md:co-translate-y-0 md:co-flex md:co-flex-col md:co-flex-1 co-transition co-duration-300 co-p-4 md:co-p-4 md:co-pb-0 xl:co-p-6 xl:co-pb-0  co-bg-light-grey'
                    "
                >
                    <ScrollShadow :class="currentStep >= 8 ? 'co-scroll-wrapper' : ''">
                        <div
                            v-if="currentStep >= 1"
                            class="co-flex co-flex-col co-gap-4 co-flex-1 co-text-text-grey"
                            ref="content"
                        >
                            <div v-if="currentStep >= 2" class="step-2 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Your Measurements </div>
                                <div class="">
                                    <dl class="co-flex co-flex-col 2xl:co-mr-16 co-text-sm">
                                        <div class="pair co-w-full co-flex co-justify-between co-gap-4">
                                            <dt>Opening Width Top:</dt>
                                            <dd class="co-text-right">{{ props.inputData[1].topBrick }}mm</dd>
                                        </div>
                                        <div class="pair co-w-full co-flex co-justify-between co-gap-4">
                                            <dt>Opening Width Bottom:</dt>
                                            <dd class="co-text-right">{{ props.inputData[1].bottomBrick }}mm</dd>
                                        </div>
                                        <div class="pair co-w-full co-flex co-justify-between co-gap-4">
                                            <dt>Floor:</dt>
                                            <dd class="co-text-right">{{ props.inputData[1].floorLintel }}mm</dd>
                                        </div>
                                        <div class="pair co-w-full co-flex co-justify-between co-gap-4">
                                            <dt>Top Box:</dt>
                                            <dd class="co-text-right">{{ props.inputData[1].topBox }}mm</dd>
                                        </div>
                                        <div class="pair co-w-full co-flex co-justify-between co-gap-4">
                                            <dt>Left Pillar:</dt>
                                            <dd class="co-text-right">{{ props.inputData[1].leftPillar }}mm</dd>
                                        </div>
                                        <div class="pair co-w-full co-flex co-justify-between co-gap-4">
                                            <dt>Left Pillar:</dt>
                                            <dd class="co-text-right">{{ props.inputData[1].rightPillar }}mm</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                            <div v-if="currentStep >= 3" class="step-1 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Sizing Option </div>
                                <div class="co-flex co-justify-between">
                                    <div class="co-pr-4 co-text-sm">{{ props.inputData[2].size }}</div>
                                    <div class="co-text-sm" v-if="Number(props.inputData[2].cost) > 0"> &pound;{{ Number(props.inputData[2].cost) / 100 }} </div>
                                </div>
                            </div>

                            <div v-if="currentStep >= 4" class="step-3 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Door Colour </div>
                                <div class="co-flex co-justify-between">
                                    <div class="co-text-sm">
                                        <div class="co-flex co-items-center co-space-x-2 co-text-sm">
                                            <div
                                                class="co-w-6 co-h-6 co-rounded co-border co-border-grey"
                                                :style="{ 'background-color': props.inputData[3].color.hex }"
                                                :title="props.inputData[3].name"
                                            ></div>
                                            <span>{{ props.inputData[3].name }}</span>
                                        </div>
                                    </div>
                                    <div class="co-text-sm" v-if="Number(props.inputData[3].cost) > 0"> &pound;{{ Number(props.inputData[3].cost) / 100 }} </div>
                                </div>
                            </div>

                            <div v-if="currentStep >= 5" class="step-4 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Garage Door Positioning </div>
                                <div class="co-flex co-justify-between">
                                    <div class="co-text-sm">{{ props.inputData[4].title }}</div>
                                    <div class="co-text-sm" v-if="Number(props.inputData[4].cost) > 0"> &pound;{{ Number(props.inputData[4].cost) / 100 }} </div>
                                </div>
                            </div>

                            <div v-if="currentStep >= 6" class="step-5 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Top Box Position </div>
                                <div class="co-flex co-justify-between">
                                    <div class="co-text-sm">{{ props.inputData[5].title }}</div>
                                    <div class="co-text-sm" v-if="Number(props.inputData[5].cost) > 0"> &pound;{{ Number(props.inputData[5].cost) / 100 }} </div>
                                </div>
                            </div>

                            <div v-if="currentStep >= 7" class="step-6 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Motor Position </div>
                                <div class="co-flex co-justify-between">
                                    <div class="co-text-sm">{{ props.inputData[6].title }}</div>
                                    <div class="co-text-sm" v-if="Number(props.inputData[6].cost) > 0"> &pound;{{ Number(props.inputData[6].cost) / 100 }} </div>
                                </div>
                            </div>

                            <div v-if="currentStep >= 8" class="step-7 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase">
                                    Guide Runners & Top Box Colour
                                </div>
                                <div class="co-flex co-justify-between">
                                    <div class="co-flex co-items-center co-space-x-2 co-text-sm">
                                        <div
                                            class="co-w-6 co-h-6 co-rounded co-border co-border-grey"
                                            :style="{ 'background-color': props.inputData[7].color.hex }"
                                        ></div>
                                        <span>{{ props.inputData[7].name }}</span>
                                    </div>
                                    <div class="co-flex co-items-center co-text-sm" v-if="Number(props.inputData[7].cost) > 0"> &pound;{{ Number(props.inputData[7].cost) / 100 }} </div>
                                </div>
                            </div>

                            <div v-if="currentStep >= 9" class="step-6 co-flex co-flex-col">
                                <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Extras</div>
                                <div class="co-flex co-justify-between co-gap-4" v-for="item in props.inputData[8].extras">
                                    <div class="co-text-sm"> {{ item.title }} </div>
                                    <div class="co-text-sm" v-if="Number(item.price) > 0"> &pound;{{  item.price / 100 }}</div>
                                </div>
                            </div>
                        </div>
                    </ScrollShadow>
                    <!-- <div class="totals co-flex co-flex-col co-gap-2 co-p-2 co-mt-4 co-select-none"> -->
                    <div class="co-flex co-flex-col">
                        <div class="item co-flex co-justify-between co-items-center">
                            <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Extras </div>
                            <div class="co-text-lg co-text-right">
                                <span v-if="currentStep >= 9"> &pound;{{ (Number(totalExtras) / 100).toFixed(2) }} </span>
                                <span v-else class="co-text-grey"> &pound;0.00 </span>
                            </div>
                        </div>
                        <div class="item co-flex co-justify-between co-items-center">
                            <div class="co-text-xs co-font-semibold co-text-mid-grey co-uppercase"> Delivery </div>
                            <div class="co-text-lg co-text-right">
                                <span v-if="currentStep >= 10"> &pound; {{ (props.customerData.deliveryCost / 100).toFixed(2) }}</span>
                                <span v-else class="co-text-grey"> &pound;0.00 </span>
                            </div>
                        </div>
                        <div
                            class="item co-hidden md:co-flex co-justify-between co-items-center co-border-t co-border-gray-300 co-mt-2 co-pt-2"
                            :class="currentStep >= 3 ? 'co-text-twf-blue' : 'co-text-mid-grey'"
                        >
                            <div class="co-text-xs co-font-bold co-uppercase"> Total </div>
                            <div class="co-text-right co-text-2xl co-font-bold">
                                &pound;{{ (Number(formattedTotalQuote) / 100).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="co-flex-1"></div>

                <div class="action-buttons co-relative co-z-40 co-p-4 xl:co-p-6 co-bg-light-grey !co-pointer-events-auto">
                    <div
                        v-if="currentStep > 1"
                        class="item co-flex md:co-hidden md:co-flex-col co-gap-2 co-justify-between co-items-center co-p-2 co-bg-white co-rounded co-mb-4"
                    >
                        <InfoIconAlt @click="toggleSummary" />
                        <div class="co-flex co-gap-2 co-items-center">
                            <div class="co-text-xs co-font-bold co-text-twf-blue co-uppercase"> Total </div>
                            <div class="co-text-right co-text-xl co-font-bold co-text-co-black">
                                &pound;{{ (Number(formattedTotalQuote) / 100).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                    <div class="error">
                        <div v-if="props.error" class="co-text-lg co-bg-info-blue co-text-white co-text-center co-p-1 co-mb-4 co-rounded-sm co-font-normal">{{ props.error }}</div>
                    </div>
                    <div class="co-flex co-flex-row md:co-flex-col xl:co-flex-row co-gap-4">
                        <BackButton @click="goBack"></BackButton>
                        <NextButton @click="goNext" :is-enabled="props.enabledNextButton"></NextButton>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<style scoped></style>
