<script setup lang="ts">
const emit = defineEmits(['next']);
import { ref } from 'vue';
import InfoIcon from '@/assets/images/icon_info.svg';
import CloseIcon from '@/assets/images/icon_close.svg';

const isToggled = ref(false);

const next = () => {
    emit('next');
};

const toggleIcon = () => {
    isToggled.value = !isToggled.value;
};
</script>

<template>
    <button @click="toggleIcon">
        <component :is="isToggled ? CloseIcon : InfoIcon" class="co-w-full co-h-full" />
    </button>
</template>
