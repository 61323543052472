<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useIsMobile } from '@/composables/useIsMobile';
import axios from 'axios';

// Views
import IntroVideo from '@/views/IntroVideo.vue';

// Components
import MobileHeader from '@/components/MobileHeader.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import Summary from '@/components/Summary.vue';

let router = useRouter();

const { isMobile } = useIsMobile();
const measurementsComplete = ref<boolean>(false);

// Reactive variables
const totalQuote = ref<number>(0);
const progress = computed(() => (currentStep.value / 100) * 10);
const windowWidth = ref(window.innerWidth);
const inputData = ref<any>({
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
});
const customerData = ref<object>({
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    townCity: '',
    postcode: '',
    email: '',
    phone: '',
    deliveryMethod: '',
})

const updateWidth = () => {
    windowWidth.value = window.innerWidth;
};

const currentStep = ref<number>(0);
const enabledNextButton = ref<boolean>(false);

watch(
    () => currentStep.value,
    () => {
        if (currentStep.value === 10) {
            const data = {
                'inputData': inputData.value,
                'customerData': customerData.value
            };
            axios.post(
                '/api/calculator/send/',
                data,
                {
                    headers: {
                        'X-CSRF-Token': (window as any).csrfToken,
                    },
                }
            ).then((response) => {
                // HTTP redirect to checkout page
                window.location.href = response.data.url;
            });
        }
    }
);

const steps = [
    { totalQuote: '0.00' },
    { totalQuote: '485.00' },
    { totalQuote: '65.00' },
    { totalQuote: '0.00' },
    { totalQuote: '0.00' },
    { totalQuote: '0.00' },
    { totalQuote: '60.00' },
    { totalQuote: '135.00' },
    { totalQuote: '65.00' },
    { totalQuote: '0.00' },
];

const handleStart = () => {
    currentStep.value = 1;

    router.push('/step/1');
    scrollCanvas();
};

const scrollCanvas = () => {
    window.setTimeout(() => {
        window.scrollTo({ top: 81, behavior: 'smooth' });
    }, 10);
};

const goBack = () => {
    enabledNextButton.value = false;

    if (currentStep.value > 0) {
        currentStep.value--;
    }
    if (currentStep.value > 1) {
        totalQuote.value -= inputData.value[currentStep.value]?.cost ?? 0;
    }

    if (currentStep.value === 0) {
        router.push('/');
    } else {
        router.push('/step/' + currentStep.value);
    }
    scrollCanvas();
};

const goNext = () => {
    if (currentStep.value < steps.length) {
        totalQuote.value += inputData.value[currentStep.value]?.cost ?? 0;
        currentStep.value++;
    }

    enabledNextButton.value = false;

    if (currentStep.value === 0) {
        router.push('/');
    } else {
        router.push('/step/' + currentStep.value);

        if (typeof gtag === 'function') {
            gtag('event', 'click', {
                event_category: 'button',
                event_label: 'step-' + currentStep.value,
                value: totalQuote.value,
            });
        }
    }
};

const updateData = (data: any) => {
    inputData.value[currentStep.value] = data;
};

const updateCustomer = (data: object) => {
    customerData.value = data;
}

const error = ref<string>('');
const setError = (message: string) => {
    error.value = message;
};

const formattedTotalQuote = computed(() => totalQuote.value.toFixed(2));

const handleMeasurementsComplete = () => {
    measurementsComplete.value = true;
    // Handle the measurements complete event here, e.g., navigate to the next step
};

// Lifecycle
onMounted(() => {
    window.addEventListener('resize', updateWidth);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
});
</script>

<template>
    <MobileHeader v-if="isMobile" />
    <div class="co-relative co-z-0 co-flex-1 co-flex co-flex-col co-justify-center co-h-full">
        <IntroVideo v-if="currentStep == 0" @start="handleStart" />

        <div v-else class="co-flex-1 co-w-full md:co-min-h-full co-flex md:co-grid md:co-grid-cols-12 co-mb-40 md:co-mb-0">
            <ProgressBar class="co-absolute co-top-0 co-w-full" :progress="progress" />
            <div class="main co-relative co-left-0 co-w-full co-col-span-9">
                <!-- Progress bar -->
                <div class="co-p-4 co-pt-6 md:co-p-8 md:co-pt-10">
                    <RouterView
                        :current-step="currentStep"
                        @updated-data="updateData"
                        @update-customer="updateCustomer"
                        @measurementsComplete="handleMeasurementsComplete"
                        @enabled-next-button="enabledNextButton = $event"
                        @go-back="goBack"
                        @error="setError"
                    />
                </div>
            </div>
            <aside
                class="sidebar co-fixed md:co-static co-z-50 co-bottom-0 co-left-0 co-right-0 md:co-col-span-3 co-flex co-flex-col co-justify-between co-pointer-events-none  md:co-pointer-events-auto"
            >
                <Summary
                    :total-quote="formattedTotalQuote"
                    :current-step="currentStep"
                    :input-data="inputData"
                    :customer-data="customerData"
                    :formatted-total-quote="formattedTotalQuote"
                    :enabled-next-button="enabledNextButton"
                    :error="error"
                    @go-back="goBack"
                    @go-next="goNext"
                />
            </aside>
        </div>
    </div>
</template>

<style></style>
