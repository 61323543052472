<script setup lang="ts">
import { ref, watch } from 'vue';

// Components
import Card from '../components/Card.vue';
import CommonFeatures from '../components/CommonFeatures.vue';

// Images
import size55mm from '@/assets/images/size_55mm.webp';
import size77mm from '@/assets/images/size_77mm.webp';

interface Option {
    imageSrc: string;
    title: string;
    width: string;
    height: string;
    description: string;
    descriptionSize: string;
    price: number;
}

const props = defineProps<{
    currentStep: number;
}>();

const emit = defineEmits(['updatedData', 'error', 'enabledNextButton']);

const selectionType = ref<'checkbox' | 'radio'>('radio');
const selectedIndexes = ref<number[]>([]);
const inputData = ref({
    question: 'Door Size',
    size: '',
    answer: '',
    cost: 0
});

watch(
    () => inputData.value,
    () => {
        if (inputData.value.size !== '') {
            inputData.value.answer = inputData.value.size;
            emit('enabledNextButton', true);
            emit('updatedData', inputData.value);
        } else {
            emit('error', 'Please select a size');
        }
    },
    { deep: true }
);

watch(
    () => selectedIndexes.value,
    () => {
        inputData.value.size =
            options.value[selectedIndexes.value[0]].title +
            ' - ' +
            options.value[selectedIndexes.value[0]].width +
            ' x ' +
            options.value[selectedIndexes.value[0]].height;
        inputData.value.cost = options.value[selectedIndexes.value[0]].price;
    },
    { deep: true }
);

// Card mockup
const options = ref<Option[]>([
    {
        imageSrc: size55mm,
        title: '55mm Slats',
        width: '1200mm to 2500mm',
        height: '2500mm',
        description: `
          <ul class="co-flex co-flex-col co-space-y-2 co-list-disc co-ml-4 co-text-sm">
            <li>55mm x 9mm aluminium insulated powder coated door slats.</li>
            <li>205mm x 205mm square fully enclosed top box aluminium powder coated.</li>
            <li>65mm x 25mm guide runners aluminium powder coated with brush inserts for noise reduction.</li>
            <li>50mm tubular motor.</li>
          </ul>
        `,
        descriptionSize: 'co-text-sm xl:co-text-base',
        price: 48500
    },
    {
        imageSrc: size77mm,
        title: '77mm Slats',
        width: '1500mm to 2845mm',
        height: '2600mm',
        description: `
          <ul class="co-flex co-flex-col co-space-y-2 co-list-disc co-ml-4 co-text-sm">
            <li>77mm x 18mm aluminium insulated powder coated door slats</li>
            <li>300mm x 300mm square fully enclosed top box aluminium powder coated</li>
            <li>90mm x 35mm guide runners aluminium powder coated with brush inserts for noise reduction</li>
            <li>100mm tubular motor</li>
          </ul>
        `,
        descriptionSize: 'co-text-sm xl:co-text-base',
        price: 79500
    },
    {
        imageSrc: size77mm,
        title: '77mm Slats',
        width: '2845mm to 3600mm',
        height: '2600mm',
        description: `
          <ul class="co-flex co-flex-col co-space-y-2 co-list-disc co-ml-4 co-text-sm">
            <li>77mm x 18mm aluminium insulated powder coated door slats</li>
            <li>300mm x 300mm square fully enclosed top box aluminium powder coated</li>
            <li>90mm x 35mm guide runners aluminium powder coated with brush inserts for noise reduction</li>
            <li>100mm tubular motor</li>
          </ul>
        `,
        descriptionSize: 'co-text-sm xl:co-text-base',
        price: 94500
    },
    {
        imageSrc: size77mm,
        title: '77mm Slats',
        width: '3600mm to 4950mm',
        height: '2600mm',
        description: `
          <ul class="co-flex co-flex-col co-space-y-2 co-list-disc co-ml-4 co-text-sm">
            <li>77mm x 18mm aluminium insulated powder coated door slats</li>
            <li>300mm x 300mm square fully enclosed top box aluminium powder coated</li>
            <li>90mm x 35mm guide runners aluminium powder coated with brush inserts for noise reduction</li>
            <li>100mm tubular motor</li>
          </ul>
        `,
        descriptionSize: 'co-text-sm xl:co-text-base',
        price: 134500
    },
]);
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading">Choose your door size</h2>
    </div>

    <div
        class="co-grid co-grid-cols-1 xs:co-grid-cols-2 co-items-start co-gap-4 co-select-none"
        :class="[
            options.length === 3 ? 'lg:co-grid-cols-3' : 'co-grid-cols-2',
            options.length === 4 ? 'lg:co-grid-cols-4' : 'co-grid-cols-2',
        ]"
    >
        <Card
            v-for="(option, index) in options"
            :key="index"
            :imageSrc="option.imageSrc"
            :title="option.title"
            :width="option.width"
            :height="option.height"
            :price="option.price"
            :description="option.description"
            :descriptionSize="option.descriptionSize"
            :toggleImage="false"
            :type="selectionType"
            v-model:modelValue="selectedIndexes"
            :index="index"
        />
    </div>

    <CommonFeatures />
</template>

<style scoped></style>
