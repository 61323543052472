<script setup lang="ts">
import { ref, watch } from 'vue';
import Card from '@/components/Card.vue';

// Images
import garageDoorBetween from '@/assets/images/garage_door_position_between_fit.jpg';
import garageDoorBehind from '@/assets/images/garage_door_position_behind_fit.jpg';
import garageDoorCombo from '@/assets/images/garage_door_position_combo_fit.webp';

interface Option {
    imageSrc: string;
    title: string;
    description: string;
    descriptionSize: string;
    price: number;
}

const props = defineProps<{
    currentStep: number;
}>();

const emit = defineEmits(['updatedData', 'enabledNextButton', 'error']);

const selectionType = ref<'checkbox' | 'radio'>('radio');
const selectedIndexes = ref<number[]>([]);
const inputData = ref({
    question: 'Garage Door Position',
    title: '',
    answer: '',
    cost: 0
});

watch(
    () => inputData.value,
    () => {
        if (inputData.value.title !== '') {
            inputData.value.answer = inputData.value.title
            emit('enabledNextButton', true);
            emit('updatedData', inputData.value);
        } else {
            emit('error', 'Please select a garage door position');
        }
    },
    { deep: true }
);

watch(
    () => selectedIndexes.value,
    () => {
        inputData.value.title = options.value[selectedIndexes.value[0]].title;
        inputData.value.cost = Number(options.value[selectedIndexes.value[0]].price) * 100;
    },
    { deep: true }
);

// Card mockup
const options = ref<Option[]>([
    {
        imageSrc: garageDoorBetween,
        title: 'Between Fit',
        description:
            'The Guide Runners on an in-between fit, sit between the pillars and the top box most commonly sits under your lintel.',
        descriptionSize: 'co-text-base xl:co-text-base',
        price: 0,
    },
    {
        imageSrc: garageDoorBehind,
        title: 'Behind Fit',
        description:
            'The guide runners and top box sit on the back of the garage door pillars and behind the lintel. To achieve the roller garage door to be fitted like this, you would need 65mm each side clearance on the back of your pillars.',
        descriptionSize: 'co-text-base xl:co-text-base',
        price: 0,
    },
    {
        imageSrc: garageDoorCombo,
        title: 'Combination Fit',
        description:
            'One guide runner attached behind the reveal and one guide runner attached to the flat wall allowing the top box to be installed behind the lintel.',
        descriptionSize: 'co-text-base xl:co-text-base',
        price: 0,
    },
]);
</script>

<template>
    <div class="section-header co-mb-4">
        <h2 class="co-view-heading"> Garage Door Position </h2>
    </div>

    <div class="co-grid xs:co-grid-cols-2 sm:co-grid-cols-3 md:co-grid-cols-2 xl:co-grid-cols-3 co-items-start co-gap-4 co-select-none">
        <Card
            v-for="(option, index) in options"
            :key="index"
            :imageSrc="option.imageSrc"
            :title="option.title"
            :description="option.description"
            :descriptionSize="option.descriptionSize"
            :toggleImage="false"
            :price="option.price"
            :type="selectionType"
            v-model:modelValue="selectedIndexes"
            :index="index"
        />
    </div>
</template>

<style scoped>
.card-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>
