<script setup lang="ts">
import { useIsMobile } from '@/composables/useIsMobile';
import ModalVideo from '@/components/ModalVideo.vue';
import VideoThumb from '@/assets/images/video_placeholder.webp';

const { isMobile } = useIsMobile();
const VimeoVideoId = '975276059';
const emit = defineEmits(['start']);

// emit start event to parent
const start = () => {
    emit('start');
};
</script>

<template>
    <div class="co-flex co-flex-col co-justify-center co-items-center co-h-full">
        <div class="co-flex co-flex-col md:co-grid md:co-grid-cols-2 co-items-center co-gap-8 co-p-8 co-py-0 lg:co-px-16">
            <div class="co-flex-1 co-flex co-flex-col co-items-start co-space-y-4">
                <h2 class="co-responsive-heading co-text-3xl md:co-text-4xl xl:co-text-5xl co-text-twf-blue co-font-bold co-mb-2">
                    <span class="co-block">Welcome to the </span>
                    <span class="co-block">TWF Garage Door </span>
                    <span class="co-block">Configurator</span>
                </h2>
                <ModalVideo
                    v-if="isMobile"
                    :thumb="VideoThumb"
                    :thumbWidth="1024"
                    :thumbHeight="576"
                    thumbAlt="Modal video thumbnail"
                    :videoId="VimeoVideoId"
                    :videoWidth="1920"
                    :videoHeight="1080"
                />
                <p class="co-my-2 md:co-my-4 co-text-base md:co-text-lg">
                    With more than two decades of experience, TWF Garage Doors offers bespoke solutions that meet your requirements. For high-quality roller garage doors for sale nationwide, trust us to deliver excellence and reliability.
                </p>
                <button
                    class="co-p-4 co-bg-twf-blue co-text-yellow co-font-bold co-text-xl lg:co-text-2xl co-rounded co-select-none"
                    @click="start"
                    >Start Configuring</button
                >
            </div>
            <div v-if="!isMobile" class="co-w-full co-flex-1 co-flex">
                <div class="video co-relative co-w-full">
                    <ModalVideo
                        :thumb="VideoThumb"
                        :thumbWidth="1024"
                        :thumbHeight="576"
                        thumbAlt="Modal video thumbnail"
                        :videoId="VimeoVideoId"
                        :videoWidth="1920"
                        :videoHeight="1080"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.co-responsive-heading {
    font-size: clamp(1.875rem, 7vw, 2.575rem);
    line-height: 1.15;
}
</style>
